import React from 'react';
// import React, { useState } from 'react';
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonButtons,
  IonButton,
  IonToolbar,
  IonRadio,
  IonRadioGroup,
  IonHeader,
  IonTitle,
  IonPage
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { cashOutline, analyticsOutline, analyticsSharp, pricetagOutline, pricetagSharp, gitNetworkOutline, gitNetworkSharp, 
          fileTrayFullOutline, fileTraySharp,
          gridOutline, chevronBackOutline, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';

import type { RadioGroupCustomEvent } from '@ionic/react';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

// const [menuType, setMenuType] = useState('overlay')
const appPages: AppPage[] = [
  {
    title: 'Administración cartola',
    url: '/factura',
    iosIcon: fileTrayFullOutline,
    mdIcon: fileTraySharp
  },
  {
    title: 'Datos',
    url: '/datos',
    iosIcon: analyticsOutline,
    mdIcon: analyticsSharp
  },
  {
    title: 'Adquisiciones',
    url: '/adquisiciones',
    iosIcon: pricetagOutline,
    mdIcon: pricetagSharp
  },
  {
    title: 'Billetaje',
    url: '/billetaje',
    iosIcon: cashOutline,
    mdIcon: cashOutline
  },

  {
    title: 'Relación',
    url: '/relacion',
    iosIcon: gitNetworkOutline,
    mdIcon: gitNetworkSharp
  }
];

const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

const Menu: React.FC = () => {
  const location = useLocation();

  const toggleMenu=()=>{
    document.querySelector('ion-menu')!.style.display="none"
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonItem>
            <IonListHeader>Esfocar</IonListHeader>

            <IonButtons slot="end">
              <IonButton onClick={toggleMenu}>
                <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonItem>

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
    // <>
    //   <IonMenu type="overlay" contentId="main-content">
    //     <IonHeader>
    //       <IonToolbar>
    //         <IonTitle>Menu Content</IonTitle>
    //       </IonToolbar>
    //     </IonHeader>
    //     <IonContent className="ion-padding">
    //       <IonMenuToggle>
    //         <IonButton>Click to close the menu</IonButton>
    //       </IonMenuToggle>
    //     </IonContent>
    //   </IonMenu>
    //   <IonPage id="main-content">
    //     <IonHeader>
    //       <IonToolbar>
    //         <IonTitle>Menu</IonTitle>
    //       </IonToolbar>
    //     </IonHeader>
    //     <IonContent className="ion-padding">
    //       <h2>Select an overlay type:</h2>
    //       <IonRadioGroup
    //         value={menuType}
    //         onIonChange={(event: RadioGroupCustomEvent) => {
    //           setMenuType(event.detail.value);
    //         }}
    //       >
    //         <IonItem>
    //           <IonRadio value="overlay">
    //             <code>overlay</code>
    //           </IonRadio>
    //         </IonItem>
    //         <IonItem>
    //           <IonRadio value="reveal">
    //             <code>reveal</code>
    //           </IonRadio>
    //         </IonItem>
    //         <IonItem>
    //           <IonRadio value="push">
    //             <code>push</code>
    //           </IonRadio>
    //         </IonItem>
    //       </IonRadioGroup> <br />
    //       <IonMenuToggle>
    //         <IonButton>Click to open the menu</IonButton>
    //       </IonMenuToggle>
    //     </IonContent>
    //   </IonPage>
    //   </>
  );
};

export default Menu;
