import React from 'react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'

// const URL_SERVER_API='http://localhost:7000'
const URL_SERVER_API='https://esfocar.apiland.website'

const getTokenSession= ()=>{
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token-session')

        if(token){
            resolve(token)
        }else{
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000,
                headers: [
                    {'Content-Type': 'application/x-www-form-urlencoded'},
                    {'Accept': 'application/json'}
                ]
            });

            apiClient.post('/api/v1/gettokensession', {key: new Date().toDateString()})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });
        }
    })
}

export const getBilletaje = async (year) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 5000
            });

            apiClient.get(`${URL_SERVER_API}/api/v1/billetaje?year=${year}`, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getGlossFile = async () => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/glossfile`, {}, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const addGlossFile  = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/addglossfile`, params, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const updateGlossFile = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.put(`${URL_SERVER_API}/api/v1/updateglossfile`, params, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const deleteGlossFile = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` },
                data:params
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.delete(`${URL_SERVER_API}/api/v1/deleteglossfile`, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getUserAuthenticate = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000,
                // headers: [
                //     {'Content-Type': 'application/json'},
                //     {'Accept': 'application/json'},
                //     {'Authorization': `Bearer ${data.token}`}
                // ]
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/userauthenticate`, params, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getStudentFile = async () => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000,
                // headers: [
                //     {'Content-Type': 'application/json'},
                //     {'Accept': 'application/json'},
                //     {'Authorization': `Bearer ${data.token}`}
                // ]
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/studentfile`, {}, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getStudentFileRelation = async () => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000,
                // headers: [
                //     {'Content-Type': 'application/json'},
                //     {'Accept': 'application/json'},
                //     {'Authorization': `Bearer ${data.token}`}
                // ]
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/studentfilerelation`, {}, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getAdquisitionMovement = async () => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.get(`${URL_SERVER_API}/api/v1/adquisitionmovement`, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getAdquisitionMovementByProof = async (proof) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.get(`${URL_SERVER_API}/api/v1/adquisitionmovementbyproof?id=${proof}`, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const addAdquisitionMovement  = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/adquisitionmovement`, params, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getStudentMovement = async (id) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.get(`${URL_SERVER_API}/api/v1/studentmovement?id=${id}`, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const addStudentMovement = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 5000
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/studentmovement`, params, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const updateStudentMovement = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.put(`${URL_SERVER_API}/api/v1/studentmovement`, params, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const deleteStudentMovement = async (params) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` },
                data:params
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.delete(`${URL_SERVER_API}/api/v1/deletestudentmovement`, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}

export const getSpendingGroup = async (id) => {
    return new Promise((resolve, reject) => {
        getTokenSession().then(data => {
            const headers = {
                headers: { Authorization: `Bearer ${data.token}` }
            };    
        
            const apiClient = axios.create({
                baseURL: URL_SERVER_API,
                timeout: 1000
            });

            apiClient.post(`${URL_SERVER_API}/api/v1/spendinggroup`, {}, headers)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject({error: error});
            });

        }).catch(error => {
            reject({error: error});
        });
    })
}