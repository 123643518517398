import React from 'react';
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButtons, IonButton, IonSearchbar } from '@ionic/react';
import { useParams } from 'react-router';
import { DataGrid, GridRowsProp, GridColDef, GridColumnGroupingModel, GridRowModesModel, GridRowModes, GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { IonIcon, IonCol, IonGrid, IonRow } from '@ionic/react';
import { IonInput, IonCheckbox, IonSelect, IonSelectOption, IonAlert, useIonAlert, IonModal, IonList, IonItem, useIonToast } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react'
// import { search, closeOutline, gridOutline, chevronBackOutline, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import { OverlayEventDetail } from '@ionic/core/components';

import Toolbar from '@mui/material/Toolbar';
import { closeOutline, sendOutline, navigateOutline, checkmarkDoneOutline, search, lockClosed, add, trash, create, pencil } from 'ionicons/icons';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

import {
  getAdquisitionMovement, addAdquisitionMovement, getGlossFile
} from '../service/dataservice';

import DenseMenu from './menu-main'
import './Page.css';

const Adquisiciones: React.FC = () => {
  interface AdquisitionMovements {
    acmfecha: Date | undefined;
    gloid:number;
    acmremuneracion:number;
    acmcomprobante:string;
    acmfactura:string;
    acmfecha2:Date;
    acmvalortotal:number;
    acmvalorindividual:number;
    acmsaldo:number;
    spgorden:number;
    spgtipo:string;
    trvfactor1:number;
    trvfactor2:number;
    trvfactor3:number;
    trvfactor4:number;
    trvfactor5:number;
    acmingresoalimentacion:number;
    acmdeudaalimentacion:number;
    acmingresolavado:number;
    acmdeudalavado:number;
    acmingresovestuario:number;
    acmdeudavestuario:number;
    acmingresotextoestudio:number;
    acmdeudatextoestudio:number;
    acmingresobienesmuebles:number;
    acmdeudabienesmuebles:number;
  }

  interface GlossFile{
    id: number;
    descripcion: string;
  }

  // const { name, titlecontent } = useParams<{ name: string, titlecontent:string;}>();
  const name='Adquisiciones'

  const [messageToast] = useIonToast();

  const handleModifyClick = (rowSelected:any)=>{
    const selectedRowsData=rowSelected.map((id:any) => rows.find((row) => row.id === id));
console.log("selectedRowsData:", selectedRowsData)
    // setRowSelected(selectedRowsData)

    const fecha2=selectedRowsData[0].acmfecha2.substr(6, 4) + '-' + selectedRowsData[0].acmfecha2.substr(3, 2) + '-' + selectedRowsData[0].acmfecha2.substr(0, 2)

    setFecha(selectedRowsData[0].acmfecha)
    setComprobante(selectedRowsData[0].acmcomprobante)
    setGlosa(selectedRowsData[0].glodescripcion)
    setGlosaID(selectedRowsData[0].gloid)
    setRemuneracion75(selectedRowsData[0].acmremuneracion)
    setFactura(selectedRowsData[0].acmfactura)
    setFecha2(fecha2)
    setValorTotal(selectedRowsData[0].acmvalortotal)
    setValorIndividual(selectedRowsData[0].acmvalorindividual)

    // setRemuneracion75()
    // setDebe(dataRow[0].debe)
    // setHaber(dataRow[0].haber)

    // setGasto(spendingGroup[dataRow[0].spgid])
    // setEditMode(true)

    // const [fecha, setFecha]=useState<any>()
    // const [comprobante, setComprobante]=useState<any>()
    // const [glosa, setGlosa]=useState<any>()
    // const [glosaID, setGlosaID]=useState<any>()
    // const [glosaFactor, setGlosaFactor]=useState<any>(null)
  
    // const [remuneracion75, setRemuneracion75]=useState<any>(null)
    // const [factura, setFactura]=useState<any>()
    // const [fecha2, setFecha2]=useState<any>()
    // const [valorTotal, setValorTotal]=useState<any>()
    // const [valorIndividual, setValorIndividual]=useState<any>()
  
  }

  const showMesssage=(position: 'top' | 'middle' | 'bottom', msg:any)=>{
    messageToast({
      message: msg,
      duration: 1500,
      position: position,
    });
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  const toggleMenu=(visible:boolean)=>{
    document.querySelector('ion-menu')!.style.display=visible? "block" : "none"
  }

  const markTouched = () => {
    setIsTouched(true);
  };

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === 'confirm') {
      // setMessage(`Hello, ${ev.detail.data}!`);
    }
  }

  const handleItemSearchGlossClick= (e:any)=>{
    setGlosa(e.descripcion)
    setGlosaID(e.id)
    setGlosaFactor(e.trvfactor1)
    modalGlosa.current?.dismiss()
  }

  const handleSearchGlossInput = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    if(query.length==0){
      setResultsGlossSearch(glossFile.filter((d) => d.descripcion.toLowerCase().indexOf('') > -1));
    }else{
      setResultsGlossSearch(glossFile.filter((d) => d.descripcion.toLowerCase().indexOf(query) > -1));
    }
    setGlosaSearch(target.value!);
  };

  const [isTouched, setIsTouched] = useState(false);
  const [isLoaded, setIsLoaded]= React.useState<boolean>(false)

  const [rowSelected, setRowSelected] = React.useState<any>([])

  const [fecha, setFecha]=useState<any>()
  const [comprobante, setComprobante]=useState<any>()
  const [glosa, setGlosa]=useState<any>()
  const [glosaID, setGlosaID]=useState<any>()
  const [glosaFactor, setGlosaFactor]=useState<any>(null)

  const [remuneracion75, setRemuneracion75]=useState<any>(null)
  const [factura, setFactura]=useState<any>()
  const [fecha2, setFecha2]=useState<any>()
  const [valorTotal, setValorTotal]=useState<any>()
  const [valorIndividual, setValorIndividual]=useState<any>()
  
  const [glosaSearch, setGlosaSearch] = useState('')
  const [glossFile, setGlossFile]= React.useState<GlossFile[]>([]);
  const [resultsGlossSearch, setResultsGlossSearch] = useState([...glossFile]);

  const [adquisitionMovement, setAdquisitionMovement]= React.useState<AdquisitionMovements[]>([]);

  const [editMode, setEditMode]=useState<boolean>()
  
  const rows: GridRowsProp = adquisitionMovement
  const columns: GridColDef[] = [
    {field:'id', headerName:'ID', width: 50, editable: false},
    {field:'acmfecha', headerName:'Fecha', width: 100, editable: false},
    {field:'glodescripcion', headerName:'Glosa', width: 300, editable: false},
    {field:'acmremuneracion', headerName:'Remuneración 75%', width: 150, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'acmcomprobante', headerName:'Egreso/Traspaso', width: 150, editable: false},
    {field:'acmfactura', headerName:'Factura', width: 100, editable: false},
    {field:'acmfecha2', headerName:'Fecha', width: 100, editable: false, valueFormatter: (value) => value=='00-00-0000' ? '' : value},
    {field:'acmvalortotal', headerName:'Valor total', width: 100, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmvalorindividual', headerName:'Valor individual', width: 150, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmsaldo', headerName:'Saldo', width: 100, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmingresoalimentacion', headerName:'Ingreso', width: 100, editable: true, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmdeudaalimentacion', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmingresolavado', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmdeudalavado', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmingresovestuario', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmdeudavestuario', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmingresotextoestudio', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmdeudatextoestudio', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmingresobienesmuebles', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'acmdeudabienesmuebles', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
  ]

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'alimentacion',
      description: 'Information about the character',
      headerName: 'Alimentación',
      headerAlign: 'center',
      children: [{ field: 'acmingresoalimentacion' }, { field: 'acmdeudaalimentacion' }]
    },
    {
      groupId: 'lavado',
      description: 'Information about the character',
      headerName: 'Lavado',
      headerAlign: 'center',
      children: [{ field: 'acmingresolavado' }, { field: 'acmdeudalavado' }]
    },
    {
      groupId: ' vestuario',
      description: 'Information about the character',
      headerName: 'Vestuario y equipo',
      headerAlign: 'center',
      children: [{ field: 'acmingresovestuario' }, { field: 'acmdeudavestuario' }]
    },
    {
      groupId: ' textos',
      description: 'Information about the character',
      headerName: 'Textos de estudio',
      headerAlign: 'center',
      children: [{ field: 'acmingresotextoestudio' }, { field: 'acmdeudatextoestudio' }]
    },
    {
      groupId: ' bienes',
      description: 'Information about the character',
      headerName: 'Bienes y muebles',
      headerAlign: 'center',
      children: [{ field: 'acmingresobienesmuebles' }, { field: 'acmdeudabienesmuebles' }]
    }
  ];

  const clearValuesOfForm=()=>{
    // setFecha(null)
    // setComprobante(null)
    // setGlosa(null)
    // setGasto(spendingGroup[0])
    // setDebe(null)
    // setHaber(null)
  }

  const setAdquisitionSums= (data:AdquisitionMovements[])=>{
    let saldoAnterior=0//dquisitionMovement[0].acmvalorindividual
    data.forEach(movement=>{
      saldoAnterior=saldoAnterior + (movement.acmvalorindividual ==0 ? movement.acmremuneracion : movement.acmvalorindividual)
      console.log("saldoAnterior:", saldoAnterior, saldoAnterior * (-1))
      movement.acmsaldo= saldoAnterior * (-1)//- movement.acmvalorindividual
      
      switch(movement.spgorden) {
        case 1:
          if(movement.spgtipo==='D') {movement.acmingresoalimentacion=movement.acmvalorindividual}
          if(movement.spgtipo==='H') {movement.acmdeudaalimentacion=movement.acmvalorindividual}
          break;
        case 2:
          if(movement.spgtipo==='D') {movement.acmingresolavado=movement.acmvalorindividual}
          if(movement.spgtipo==='H') {movement.acmdeudalavado=movement.acmvalorindividual}
          break;
        case 3:
          if(movement.spgtipo==='D') {movement.acmingresovestuario=movement.acmvalorindividual}
          if(movement.spgtipo==='H') {movement.acmdeudavestuario=movement.acmvalorindividual}
          break;
        case 4:
          if(movement.spgtipo==='D') {movement.acmingresotextoestudio=movement.acmvalorindividual}
          if(movement.spgtipo==='H') {movement.acmdeudatextoestudio=movement.acmvalorindividual}
          break;
        case 5:
          if(movement.spgtipo==='D') {movement.acmingresobienesmuebles=movement.acmvalorindividual}
          if(movement.spgtipo==='H') {movement.acmdeudabienesmuebles=movement.acmvalorindividual}
          break;
        case 6:
          if(movement.spgtipo==='D') {
            movement.acmingresoalimentacion=movement.acmremuneracion*movement.trvfactor1
            movement.acmingresolavado=movement.acmremuneracion*movement.trvfactor2
            movement.acmingresovestuario=movement.acmremuneracion*movement.trvfactor3
            movement.acmingresotextoestudio=movement.acmremuneracion*movement.trvfactor4
            movement.acmingresobienesmuebles=movement.acmremuneracion*movement.trvfactor5
          }
          break;
      }
      
      // movement.totalDeudas=movement.debe + movement.haber + saldoAnterior
      //saldoAnterior=saldoAnterior + movement.acmvalorindividual
    })

    console.log("data:", data)
    return data
    
  }

  const saveMovement=(isOK:any)=>{
    if (isOK){
      const body={
        acmfecha:fecha
        ,gloid:glosaID
        ,acmremuneracion:remuneracion75
        ,acmcomprobante:comprobante
        ,acmfactura:factura
        ,acmfecha2:fecha2
        ,acmvalortotal:valorTotal
        ,acmvalorindividual:valorIndividual
        ,acmsaldo:0
        ,acmingresoalimentacion:0
        ,acmdeudaalimentacion:0
        ,acmingresolavado:0
        ,acmdeudalavado:0
        ,acmingresovestuario:0
        ,acmdeudavestuario:0
        ,acmingresotextoestudio:0
        ,acmdeudatextoestudio:0
        ,acmingresobienesmuebles:0
        ,acmdeudabienesmuebles:0
      }

      addAdquisitionMovement(body).then(data => {
        showMesssage('top', 'La información se ha actualizado satisfactoriamente...')
        setFecha(null)
        setGlosa(null)
        setRemuneracion75(null)
        setComprobante(null)
        setFactura(null)
        setFecha2(null)
        setValorTotal(null)
        setValorIndividual(null)

        getAdquisitionMovement().then(data => {
          setAdquisitionMovement (setAdquisitionSums(data.data))          
        })
        .catch(error => {
          setIsLoaded(false)
          console.log(error)
        })      
    
      })
      .catch(error => {
        setIsLoaded(false)
        console.log(error)
      })      
      
      setIsLoaded(true)
    }

  }

  const checkline=()=>{
    if(glosaFactor==null){
      return fecha != undefined && glosa != undefined && comprobante != undefined && factura != undefined && fecha2 != undefined  && valorTotal != undefined && valorIndividual != undefined 
    }else{
      return fecha != undefined && glosa != undefined && remuneracion75 != undefined && comprobante != undefined       
    }
  }

  useEffect(() => {
    const loadData = async () => {
      setIsLoaded(true)

      getAdquisitionMovement().then(data => {
        setAdquisitionMovement (setAdquisitionSums(data.data))

        getGlossFile().then(data => {
          setGlossFile(data.data)
          setResultsGlossSearch(data.data)
        })
        .catch(error => {
          setIsLoaded(false)
        })

        // toggleMenu(false)
      })
      .catch(error => {
        setIsLoaded(false)
      })
    }
    loadData();
  }, []);

  const modalGlosa= useRef<HTMLIonModalElement>(null);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons> */}
          <IonTitle>{name}</IonTitle>

          <IonButtons slot="start">
            <DenseMenu />
            {/* <IonButton onClick={() => {toggleMenu(true)}}>
              <IonIcon slot="icon-only" icon={gridOutline}></IonIcon>
            </IonButton> */}
          </IonButtons>

        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="container-evento">
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-padding">
                    <IonRow>
                      <IonCol size="1.5">
                        <IonInput
                          value={fecha}
                          type='date'
                          labelPlacement="floating" 
                          label='Fecha'
                          placeholder='ej:20-03-2024'
                          onIonInput={(e) => {setFecha(e.target.value)}}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonButtons slot="end">
                            <IonButton onClick={() => setFecha(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                          </IonInput>
                      </IonCol>

                      <IonCol size="2.0">
                        <IonInput 
                            value={glosa}
                            type='text'
                            readonly={true}
                            labelPlacement="floating" 
                            label='Glosa'
                            placeholder='ej:5643245'
                            onIonInput={(e) => setGlosa(e.target.value)}//validate(event)}
                            onIonBlur={() => markTouched()}
                            helperText='Ingrese factura de gastos válida'
                            errorText="Número factura incorrecta">
                            <IonButtons slot="end" className="search-glosa">
                              <IonButton id="open-modal-glosa2">
                                <IonIcon slot="icon-only" icon={search}></IonIcon>
                              </IonButton>
                            </IonButtons>
                        </IonInput>

                        <IonModal ref={modalGlosa} trigger="open-modal-glosa2" onWillDismiss={(ev) => onWillDismiss(ev)}>
                          <IonHeader>
                            <IonToolbar>
                              <IonTitle>Búsqueda de glosas</IonTitle>
                              <IonButtons slot="end">
                                <IonButton onClick={() => modalGlosa.current?.dismiss()}>
                                  <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                                </IonButton>
                              </IonButtons>
                            </IonToolbar>
                          </IonHeader>
                          <IonContent className="ion-padding">
                            <IonSearchbar 
                              value={glosaSearch} 
                              placeholder="ej:LAVANDERIA" 
                              debounce={1000} 
                              onIonInput={(e) => { handleSearchGlossInput(e)}}>
                            </IonSearchbar>
                            <IonList>
                              {resultsGlossSearch.map((result, index) => (
                                <IonItem onClick={() =>handleItemSearchGlossClick(result)} button={true} key={index}>{result.descripcion}</IonItem>
                              ))}
                            </IonList>
                          </IonContent>
                        </IonModal>

                      </IonCol>

                      <IonCol size="1.5">
                        <IonInput
                          value={remuneracion75}
                          type='number'
                          labelPlacement="floating" 
                          label='Remuneración 75%'
                          placeholder='ej:5643245'
                          onIonInput={(e) => setRemuneracion75(e.target.value)}//validate(event)}
                          onIonBlur={() => markTouched()}
                          disabled={glosaFactor==null}
                          helperText='Ingrese un monto válido'
                          // errorText="Número factura incorrecta"
                          >
                          <IonButtons slot="end">
                            <IonButton onClick={() => setRemuneracion75(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                        </IonInput>
                      </IonCol>

                      <IonCol size="1.5">
                        <IonInput
                          value={comprobante}
                          type='text'
                          labelPlacement="floating" 
                          label='Egreso/Traspaso'
                          placeholder='ej:5643245'
                          onIonInput={(e) => setComprobante(e.target.value)}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonButtons slot="end">
                            <IonButton onClick={() => setComprobante(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                        </IonInput>
                      </IonCol>

                      <IonCol size="1.0">
                        <IonInput
                          value={factura}
                          type='text'
                          labelPlacement="floating" 
                          label='Factura'
                          placeholder='ej:5643245'
                          onIonInput={(e) => setFactura(e.target.value)}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonButtons slot="end">
                            <IonButton onClick={() => setFactura(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                        </IonInput>
                      </IonCol>

                      <IonCol size="1.5">
                        <IonInput
                          value={fecha2}
                          type='date'
                          labelPlacement="floating" 
                          label='Fecha'
                          placeholder='ej:20-03-2024'
                          onIonInput={(e) => {setFecha2(e.target.value)}}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonButtons slot="end">
                            <IonButton onClick={() => setFecha2(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                          </IonInput>
                      </IonCol>

                      <IonCol size="1.5">
                        <IonInput
                          value={valorTotal}
                          type='number'
                          labelPlacement="floating" 
                          label='Valor Total'
                          placeholder='ej:20-03-2024'
                          disabled={glosaFactor!==null}
                          onIonInput={(e) => {setValorTotal(e.target.value)}}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonButtons slot="end">
                            <IonButton onClick={() => setValorTotal(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                        </IonInput>
                      </IonCol>

                      <IonCol size="1.5">
                        <IonInput
                          value={valorIndividual}
                          type='number'
                          labelPlacement="floating" 
                          label='Valor Individual'
                          placeholder='ej:20-03-2024'
                          disabled={glosaFactor!==null}
                          onIonInput={(e) => {setValorIndividual(e.target.value)}}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonButtons slot="end">
                            <IonButton onClick={() => setValorIndividual(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                        </IonInput>
                      </IonCol>

                      {/* <IonCol size="2" className='ion-text-right'>
                        <IonButton id="save-movement" type="submit" color="primary" onClick={() =>{ saveMovement(checkline())}}>Grabar</IonButton>
                        <IonButton type="submit" color="primary" onClick={() =>{ clearValuesOfForm()}}>Limpiar</IonButton>
                      </IonCol> */}
                    </IonRow>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12" className='ion-text-right'>
                  <IonButton id="save-movement" type="submit" color="primary" disabled={!checkline()} onClick={() =>{ saveMovement(checkline())}}>Grabar</IonButton>
                  <IonButton type="submit" color="primary" onClick={() =>{ clearValuesOfForm()}}>Limpiar</IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12" className="ion-padding">
                <Toolbar
                        sx={[
                          {
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                          },
                          rowSelected.length > 0 && {
                            bgcolor: (theme) =>
                              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                          },
                        ]}
                      >
                        {rowSelected.length > 0 ? (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                          >
                            {rowSelected.length} registro(s) seleccionado(s)
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                          >
                            Detalle de movimientos
                          </Typography>
                        )}
                        {rowSelected.length > 0 ? (
                          <IonButtons slot="end">
                            <IonButton id="delete-rows" >
                              <IonIcon icon={trash} color="primary" size="large"></IonIcon>
                                <IonAlert
                                  trigger="delete-rows"
                                  header={name}
                                  subHeader="Eliminación de movimientos"
                                  message="A continuación se eliminarán los registros seleccionados, ¿Desea continuar con este proceso?"
                                  buttons={[
                                    {
                                      text: 'Volver',
                                      role: 'cancel',
                                      handler: () => {
                                        console.log('Alert canceled');
                                      },
                                    },
                                    {
                                      text: 'Eliminar',
                                      role: 'confirm',
                                      handler: () => {
                                        console.log('Alert confirmed');

                                        setIsLoaded(true)
                                        // deleteStudentMovement(rowSelected).then(data => {
                                        //   getStudentMovement(studentId).then(data => {
                                        //     setStudentMovement(data.data)
                                        //   setIsLoaded(false)
                                        //   })
                                        //   .catch(error => {
                                        //     setIsLoaded(false)
                                        //     console.log(error)
                                        //   })
                                        // })
                                        // .catch(error => {
                                        //   setIsLoaded(false)
                                        //   console.log(error)
                                        // })
                                      }
                                    }
                                  ]}
                                >
                                </IonAlert>
                            </IonButton>

                            {rowSelected.length == 1 ? (
                            <IonButton id="modify-rows" onClick={() =>handleModifyClick(rowSelected)}>
                              <IonIcon icon={create} color="primary" size="large"></IonIcon>
                            </IonButton>
                            ) : '' }
                          </IonButtons>
                        ) : '' }
                    </Toolbar>

                  <DataGrid 
                    rows={rows} 
                    columns={columns} 
                    columnGroupingModel={columnGroupingModel} 
                    editMode="row"
                    checkboxSelection
                    rowSelectionModel={rowSelected}
                    onRowSelectionModelChange={(ids) => setRowSelected(ids)}
                    // rowModesModel={rowModesModel}
                    hideFooterSelectedRowCount
                    slotProps={{ pagination: { labelRowsPerPage: 'Registros por página'}}}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Adquisiciones;
