import React from 'react';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  useIonAlert, IonAlert} from '@ionic/react';
import { useParams } from 'react-router';
import { DataGrid, GridRowsProp, GridColDef, GridColumnGroupingModel, GridRowModesModel, GridRowModes, GridActionsCellItem, GridRenderCellParams, GridFilterInputMultipleSingleSelectProps } from '@mui/x-data-grid';
import { IonCol, IonGrid, IonRow, IonButton, IonIcon } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react'
import Toolbar from '@mui/material/Toolbar';
import { closeOutline, sendOutline, navigateOutline, checkmarkDoneOutline, search, lockClosed, add, trash, create, pencil } from 'ionicons/icons';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { 
  getStudentFileRelation,
} from '../service/dataservice';

import DenseMenu from './menu-main'
import './Page.css';

const Relacion: React.FC = () => {
  interface StudentFileRelation{
    id: number;
    stfrut:string;
    gradescription: string;
    stfsexo:string;
    stfalumno:string;
    enddescription:string;
    prodescription:string;
    stadescription:string;
    billetaje:number;
    remuneracion:number;
    gastos:number;
    saldoliquidar:number;
    comprobar:number;
  }

  // const { name, titlecontent } = useParams<{ name: string, titlecontent:string;}>();
  const name='Relación'

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  const [rowSelected, setRowSelected] = React.useState<any>([])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [isLoaded, setIsLoaded]= React.useState<boolean>(false)
  const [studentFileRelation, setStudentFileRelation]= React.useState<StudentFileRelation[]>([]);
  const rows: GridRowsProp = studentFileRelation
  const columns: GridColDef[] = [
    {field:'id', headerName:'ID', width: 50, editable: false},
    {field:'stfrut', headerName:'RUT', width: 120, editable: false},
    {field:'gradescription', headerName:'Grado', width: 150, editable: false},
    {field:'stfsexo', headerName:'Sexo', width: 50, editable: false},
    {field:'stfalumno', headerName:'Apellidos y Nombres', width: 250, editable: false},
    {field:'enddescription', headerName:'Dotación', width: 150, editable: false},
    {field:'prodescription', headerName:'Promoción', width: 150, editable: false},
    {field:'resolucionbaja', headerName:'Resolución baja', width: 200, editable: false},
    {field:'fechabaja', headerName:'Fecha baja', width: 120, editable: false},
    {field:'stadescription', headerName:'Estado', width: 150, editable: false},
    {field:'billetaje', headerName:'Billetaje', width: 150, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'remuneracion', headerName:'Total Remuneración', width: 150, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'gastos', headerName:'Total Gastos', width: 150, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'saldoliquidar', headerName:'Saldo a Liquidar', width: 120, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'comprobar', headerName:'Comprobar', width: 120, editable: false, headerAlign: 'right', align:'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))}
  ]

  const handleModifyClick = (rowSelected:any)=>{
    const dataRow=rowSelected.map((id:any) => rows.find((row) => row.id === id));

  }

  const balanceAdjustment = (data:StudentFileRelation[])=>{
    console.log("data:", data)
    data.forEach(movement=>{
      movement.billetaje=movement.billetaje==null ? 0 : movement.billetaje
      movement.saldoliquidar=movement.saldoliquidar * (-1)
      movement.comprobar=movement.remuneracion-movement.billetaje
    })

    return data
  }

  useEffect(() => {
    const loadData = async () => {
      setIsLoaded(true)

      getStudentFileRelation().then(data => {
        setStudentFileRelation (balanceAdjustment(data.data))
      })
      .catch(error => {
        setIsLoaded(false)
      })
    }
    loadData();
  }, []);

  const [dataAlert] = useIonAlert();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <DenseMenu />
            {/* <IonMenuButton /> */}
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="container-evento">
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-padding">
                    <Toolbar
                        sx={[
                          {
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                          },
                          rowSelected.length > 0 && {
                            bgcolor: (theme) =>
                              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                          },
                        ]}
                      >
                        {rowSelected.length > 0 ? (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                          >
                            {rowSelected.length} registro(s) seleccionado(s)
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                          >
                            Detalle de movimientos
                          </Typography>
                        )}
                        {rowSelected.length > 0 ? (
                          <IonButtons slot="end">
                            <IonButton id="delete-rows" >
                              <IonIcon icon={trash} color="primary" size="large"></IonIcon>
                                <IonAlert
                                  trigger="delete-rows"
                                  header="Administración cartola"
                                  subHeader="Eliminación de movimientos"
                                  message="A continuación se eliminarán los registros seleccionados, ¿Desea continuar con este proceso?"
                                  buttons={[
                                    {
                                      text: 'Volver',
                                      role: 'cancel',
                                      handler: () => {
                                        console.log('Alert canceled');
                                      },
                                    },
                                    {
                                      text: 'Eliminar',
                                      role: 'confirm',
                                      handler: () => {
                                        console.log('Alert confirmed');

                                        setIsLoaded(true)
                                        // deleteStudentMovement(rowSelected).then(data => {
                                        //   getStudentMovement(studentId).then(data => {
                                        //     setStudentMovement(data.data)
                                        //   setIsLoaded(false)
                                        //   })
                                        //   .catch(error => {
                                        //     setIsLoaded(false)
                                        //     console.log(error)
                                        //   })
                                        // })
                                        // .catch(error => {
                                        //   setIsLoaded(false)
                                        //   console.log(error)
                                        // })
                                      }
                                    }
                                  ]}
                                >
                                </IonAlert>
                            </IonButton>

                            {rowSelected.length == 1 ? (
                            <IonButton id="modify-rows" onClick={() =>handleModifyClick(rowSelected)}>
                              <IonIcon icon={create} color="primary" size="large"></IonIcon>
                            </IonButton>
                            ) : '' }
                          </IonButtons>
                        ) : '' }
                    </Toolbar>

                  <DataGrid 
                        rows={rows} 
                        columns={columns} 
                        // columnGroupingModel={columnGroupingModel} 
                        editMode="row"
                        // rowModesModel={rowModesModel}
                        // checkboxSelection
                        // rowSelectionModel={rowSelected}
                        // onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        // onRowSelectionModelChange={(ids) => setRowSelected(ids)}
                        // rowSelectionModel={rowSelected}                            
                        hideFooterSelectedRowCount
                        slotProps={{ pagination: { labelRowsPerPage: 'Registros por página'}}}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Relacion;
