import React from 'react';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  useIonAlert, IonAlert, IonInput} from '@ionic/react';
import { useParams } from 'react-router';
import { DataGrid, GridRowsProp, GridColDef, GridColumnGroupingModel, GridRowModesModel, GridRowModes, GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { IonCol, IonGrid, IonRow, IonButton, IonIcon } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Toolbar from '@mui/material/Toolbar';
import { cashOutline, closeOutline, sendOutline, navigateOutline, checkmarkDoneOutline, search, lockClosed, add, trash, create, pencil } from 'ionicons/icons';

import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg } from '@ionic/react';

import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { 
  getGlossFile, getUserAuthenticate
} from '../service/dataservice';

import './login-form.css';

const Login: React.FC = () => {
  interface GlossFile{
    id: number;
    descripcion: string;
  }

  // const { name, titlecontent } = useParams<{ name: string, titlecontent:string;}>();
  const name='Autenticación usuario'

  const [rowSelected, setRowSelected] = React.useState<any>([])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [isLoaded, setIsLoaded]= React.useState<boolean>(false)
  const [glossFile, setGlossFile]= React.useState<GlossFile[]>([]);

  const [user, setUser]=useState<any>()
  const [password, setPassword]=useState<any>()

  const rows: GridRowsProp = glossFile
  const columns: GridColDef[] = [
    {field:'id', headerName:'ID', width: 150, editable: false},
    {field:'descripcion', headerName:'Glosa', width: 400, editable: false},
    {field:'ingreso', headerName:'Ingreso', width: 150, editable: false},
    {field:'egresotraspaso', headerName:'Egreso/Traspaso', width: 150, editable: false},
    {field:'factura', headerName:'Factura', width: 150, editable: false},
    {field:'fecha', headerName:'Fecha', width: 150, editable: false},
    {field:'salida', headerName:'Salida', width: 150, editable: false}
  ]

  const history = useHistory();
  const handleClickLogin=()=>{
    getUserAuthenticate({user:user, password:password}).then(data => {
      history.push('/factura')
      console.log("login ok")
    })
    .catch(error => {
      setIsLoaded(false)
      console.log(error)
    })

    
  }

  const handleModifyClick = (rowSelected:any)=>{
    const dataRow=rowSelected.map((id:any) => rows.find((row) => row.id === id));

  }

  const toggleMenu=(visible:boolean)=>{
    document.querySelector('ion-menu')!.style.display=visible? "block" : "none"
  }


  useEffect(() => {
    const loadData = async () => {
      setIsLoaded(true)

      getGlossFile().then(data => {
        setGlossFile (data.data)
      })
      .catch(error => {
        setIsLoaded(false)
      })
    }
    loadData();
  }, []);

  const [dataAlert] = useIonAlert();

  toggleMenu(false)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="container-evento">
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="3.5" className="ion-padding">
                  <IonCard className="ion-padding">
                    <IonCardHeader>
                      <IonCardTitle>ESFOCAR</IonCardTitle>
                      <IonCardSubtitle>Escuela formación de carabineros</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                    </IonCardContent>

                    <div className='ion-padding'>
                      <div className='ion-padding-top'>
                        <IonInput
                          value={user}
                          type='text'
                          labelPlacement="floating"
                          clearInput={false}
                          label='Usuario'
                          onIonInput={(e) => setUser(e.target.value)}
                          placeholder='Correo electrónico'>
                        </IonInput>
                      </div>

                      <div className='ion-padding-top'>
                        <IonInput
                          value={password}
                          type='password'
                          labelPlacement="floating"
                          clearInput={false}
                          label='Password'
                          onIonInput={(e) => setPassword(e.target.value)}
                          placeholder='Password'>
                        </IonInput>
                      </div>

                      <div className='ion-padding-top'>
                        <IonButton id="save-movement" type="submit" color="light" shape="round" expand="block" onClick={handleClickLogin}>Iniciar Session</IonButton>
                      </div>
                    </div>

                  </IonCard>
                </IonCol>

                <IonCol size="1.0" className="ion-padding"></IonCol>

                <IonCol size="2.5" className="ion-no-padding">
                  <IonImg
                    src="/assets/images/landingA.jpg">
                  </IonImg>
                </IonCol>

                <IonCol size="2.5" className="ion-no-padding">
                  <IonImg
                    src="/assets/images/landingB.jpg">
                  </IonImg>
                </IonCol>

                <IonCol size="2.5" className="ion-no-padding">
                  <IonImg
                    src="/assets/images/landingC.jpg">
                  </IonImg>
                </IonCol>

              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
