import React from 'react';
import { IonModal, IonSelect, IonSelectOption, IonItem, IonInput, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  useIonAlert, IonAlert, IonLabel} from '@ionic/react';
import { useParams } from 'react-router';
import { DataGrid, GridRowsProp, GridColDef, GridColumnGroupingModel, GridRowModesModel, GridRowModes, GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { IonCol, IonGrid, IonRow, IonButton, IonIcon, IonProgressBar } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react'
import Toolbar from '@mui/material/Toolbar';
import { addOutline, closeOutline, sendOutline, navigateOutline, checkmarkDoneOutline, search, lockClosed, add, trash, create, pencil } from 'ionicons/icons';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

import { OverlayEventDetail } from '@ionic/core/components';
import { 
  getGlossFile, addGlossFile, updateGlossFile, deleteGlossFile, getSpendingGroup
} from '../service/dataservice';

import DenseMenu from './menu-main'
import './Page.css';

const Datos: React.FC = () => {
  interface GlossFile{
    id: number;
    descripcion: string;
  }

  interface SpendingGroup{
    id: number;
    descripcion: string;
    orden: number;
    tipo:string;
  }

  // const { name, titlecontent } = useParams<{ name: string, titlecontent:string;}>();
  const name='Datos'

  const [rowSelected, setRowSelected] = React.useState<any>([])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [isLoaded, setIsLoaded]= React.useState<boolean>(false)
  const [glossFile, setGlossFile]= React.useState<GlossFile[]>([]);

  const rows: GridRowsProp = glossFile
  const columns: GridColDef[] = [
    {field:'id', headerName:'ID', width: 150, editable: false},
    {field:'descripcion', headerName:'Glosa', width: 400, editable: false},
    {field:'ingreso', headerName:'Ingreso', width: 150, editable: false},
    {field:'egresotraspaso', headerName:'Egreso/Traspaso', width: 150, editable: false},
    {field:'factura', headerName:'Factura', width: 150, editable: false},
    {field:'fecha', headerName:'Fecha', width: 150, editable: false},
    {field:'salida', headerName:'Salida', width: 150, editable: false}
  ]

  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [modalModifyVisible, setModalModifyVisible]=React.useState<boolean>(false)
  const [alertDeleteVisible, setAlertDeleteVisible]=React.useState<boolean>(false)

  const glosaRef = useRef<HTMLIonInputElement>(null);
  const factor1Ref= useRef<HTMLIonInputElement>(null);
  const factor2Ref= useRef<HTMLIonInputElement>(null);
  const factor3Ref= useRef<HTMLIonInputElement>(null);
  const factor4Ref= useRef<HTMLIonInputElement>(null);
  const factor5Ref= useRef<HTMLIonInputElement>(null);

  const [id, setID]=React.useState<any>()
  const [glosa, setGlosa]=React.useState<any>(null)
  const [gasto, setGasto]=React.useState<any>()
  const [spendingGroup, setSpendingGroup] = React.useState<SpendingGroup[]>([]);

  const [factor1, setFactor1]=React.useState<any>()
  const [factor2, setFactor2]=React.useState<any>()
  const [factor3, setFactor3]=React.useState<any>()
  const [factor4, setFactor4]=React.useState<any>()
  const [factor5, setFactor5]=React.useState<any>()

  useEffect(() => {
    const loadData = async () => {
      setIsLoaded(true)

      getGlossFile().then(data => {
        setGlossFile (data.data)

        getSpendingGroup().then(data => {
          data.data.splice(0, 0, {descripcion:'Seleccione item', id:0, orden:0, tipo:''});
          setSpendingGroup(data.data)

          setIsLoaded(false)
        })
        .catch(error => {
          setIsLoaded(false)
        })

      })
      .catch(error => {
        setIsLoaded(false)
      })
    }
    loadData();
  }, []);

  const [dataAlert] = useIonAlert();

  const handleClickListItem=()=>{
    console.log("gasto:", gasto)
  }

  const checkInput=()=>{
    if(glosa==null || gasto==undefined){
      return true
    }else{
      if(glosa!= null){
        if(glosa.length==0){
          return true
        }else{
          return false
        }
      }else{
        return true
      }
    }
  }

  const saveInformation=(newRecord:boolean)=>{
    setIsLoaded(true)
    if(newRecord){
      const body={gloid:null, glodescripcion:glosa, spgid:gasto}

      addGlossFile(body).then(data => {
        getGlossFile().then(data => {
          setGlossFile (data.data)
        })
        .catch(error => {
          setIsLoaded(false)
        })

        setIsLoaded(false)
      })
      .catch(error => {
        setIsLoaded(false)
        console.log(error)
      })
    }else{
      const body={gloid:id, glodescripcion:glosa, spgid:gasto}
      updateGlossFile(body).then(data => {
        getGlossFile().then(data => {
          setGlossFile (data.data)
        })
        .catch(error => {
          setIsLoaded(false)
        })

        setIsLoaded(false)
      })
      .catch(error => {
        setIsLoaded(false)
        console.log(error)
      })
    }

  }

  const getTypeOfSpending=(id:any)=>{
    const gastoRequest=rowSelected!==undefined ? spendingGroup.find(row => row.id === id) : {id:null, descripcion:null, orden:null, tipo:null}

    return gastoRequest?.tipo
  }

  const handleModifyClick = (rowSelected:any)=>{
    const dataRow=rowSelected===undefined ? [
      {id:undefined, descripcion:'', spgid: null}] : rowSelected.map((id:any) => rows.find((row) => row.id === id)), description=dataRow[0].descripcion, spgid=dataRow[0].spgid

    const gastoRequest=rowSelected!==undefined ? spendingGroup.find(row => row.id === dataRow[0].spgid) : {id:undefined, descripcion:null, orden:null, tipo:null}

    setID(dataRow.id)
    setGlosa(dataRow[0].descripcion)
    setGasto(gastoRequest?.id)
    setModalModifyVisible(true)
    
    if(rowSelected!==undefined){
      setFactor1(dataRow[0].trvfactor1)
      setFactor2(dataRow[0].trvfactor2)
      setFactor3(dataRow[0].trvfactor3)
      setFactor4(dataRow[0].trvfactor4)
      setFactor5(dataRow[0].trvfactor5)
    }else{
      setFactor1(null)
      setFactor2(null)
      setFactor3(null)
      setFactor4(null)
      setFactor5(null)

    }
  }

  function onWillDismiss(event: CustomEvent<OverlayEventDetail>) {
    setModalModifyVisible(false)
    setAlertDeleteVisible(false)
    if (event.detail.role === 'confirm') {
      // setMessage(`Hello, ${event.detail.data}!`);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <DenseMenu />
            {/* <IonMenuButton /> */}
          </IonButtons>
          <IonTitle>{name}</IonTitle>
          {isLoaded &&
            <IonProgressBar type="indeterminate"></IonProgressBar>
          }
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="container-evento">
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-padding">
                  <Toolbar
                      sx={[
                        {
                          pl: { sm: 2 },
                          pr: { xs: 1, sm: 1 },
                        },
                        rowSelected.length >= 0 && {
                          bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                        },
                      ]}
                    >
                      {rowSelected.length > 0 ? (
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          color="inherit"
                          variant="subtitle1"
                          component="div"
                        >
                          {rowSelected.length} registro(s) seleccionado(s)
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          color="inherit"
                          variant="subtitle1"
                          component="div"
                        >
                          Detalle de movimientos
                        </Typography>
                      )}
                      {rowSelected.length > 0 ? (
                        <IonButtons slot="end">
                          <IonButton onClick={() =>handleModifyClick(undefined)}>
                            <IonIcon icon={addOutline} color="primary" size="large"></IonIcon>
                          </IonButton>

                          <IonButton onClick={() =>setAlertDeleteVisible(true)}>
                            <IonIcon icon={trash} color="primary" size="large"></IonIcon>
                          </IonButton>

                          {rowSelected.length == 1 ? (
                          <IonButton id="modify-rows" onClick={() =>handleModifyClick(rowSelected)} >
                            <IonIcon icon={create} color="primary" size="large"></IonIcon>
                          </IonButton>
                          ) : '' }
                        </IonButtons>
                      ) : 
                        <IonButtons slot="end">
                          <IonButton onClick={() =>handleModifyClick(undefined)} >
                            <IonIcon icon={addOutline} color="primary" size="large"></IonIcon>
                          </IonButton>
                        </IonButtons>
                      }
                  </Toolbar>

                  <IonAlert
                    isOpen={alertDeleteVisible}
                    onWillDismiss={(event) => onWillDismiss(event)}
                    // trigger="delete-rows"
                    header="Actualización datos"
                    subHeader="Eliminación de glosas"
                    message="A continuación se eliminarán los registros seleccionados, ¿Desea continuar con este proceso?"
                    buttons={[
                      {
                        text: 'Volver',
                        role: 'cancel',
                        handler: () => {
                          console.log('Alert canceled');
                        },
                      },
                      {
                        text: 'Eliminar',
                        role: 'confirm',
                        handler: () => {
                          console.log('Alert confirmed');

                          setIsLoaded(true)
                          deleteGlossFile(rowSelected).then(data => {
                            getGlossFile().then(data => {
                              setGlossFile (data.data)
                              setIsLoaded(false)
                            })
                            .catch(error => {
                              setIsLoaded(false)
                              console.log(error)
                            })
                          })
                          .catch(error => {
                            setIsLoaded(false)
                            console.log(error)
                          })
                        }
                      }
                    ]}
                  >
                  </IonAlert>

                  <IonModal ref={modal} isOpen={modalModifyVisible}  onWillDismiss={(event) => onWillDismiss(event)}>
                    <IonHeader>
                      <IonToolbar>
                        <IonButtons slot="start">
                          <IonButton onClick={() => modal.current?.dismiss()}>
                            <IonIcon icon={closeOutline} color="primary" size="large"></IonIcon>
                          </IonButton>
                        </IonButtons>
                        <IonTitle>Actualización de Glosas</IonTitle>
                        <IonButtons slot="end">
                          <IonButton strong={true} onClick={() => confirm()}>
                            Confirm
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                      <IonCol size="12">
                        <div className="ion-padding-start ion-padding-end">
                          <IonInput
                            value={glosa}
                            ref={glosaRef}
                            type='text'
                            labelPlacement="floating"
                            clearInput={false}
                            label='Glosa'
                            placeholder='ej:11647580-4'
                            onIonInput={(e) => {setGlosa(e.target.value)}}
                            helperText='Ingrese factura de gastos válida'
                            errorText="Número factura incorrecta">
                          </IonInput>
                        </div>
                      </IonCol>

                      <IonCol size="12">
                        <div className="ion-padding-start ion-padding-end">
                          <IonLabel>Concepto</IonLabel>
                          <IonSelect value={gasto}
                            aria-label="Spending" interface="popover" placeholder="Seleccione item"
                            onClick={handleClickListItem}
                            onIonChange={(e) => { setGasto(e.detail.value)}}
                          >
                            {spendingGroup.map((spending) => (
                              <IonSelectOption key={spending.id} value={spending.id}>
                                {spending.descripcion}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </div>
                      </IonCol>

                      <IonRow>
                        <IonCol size="6">
                          <div className="ion-padding-start ion-padding-end">
                            <IonInput
                              value={factor1}
                              ref={factor1Ref}
                              type='text'
                              labelPlacement="floating"
                              clearInput={false}
                              label='Factor (1)'
                              disabled={gasto===undefined || (gasto!==undefined && getTypeOfSpending(gasto)!=='D')}
                              placeholder='ej:11647580-4'
                              onIonInput={(e) => {setFactor1(e.target.value)}}
                              helperText='Ingrese factura de gastos válida'
                              errorText="Número factura incorrecta">
                            </IonInput>
                          </div>
                        </IonCol>

                        <IonCol size="6">
                          <div className="ion-padding-start ion-padding-end">
                            <IonInput
                              value={factor2}
                              ref={factor2Ref}
                              type='text'
                              labelPlacement="floating"
                              clearInput={false}
                              label='Factor (2)'
                              disabled={gasto===undefined || (gasto!==undefined && getTypeOfSpending(gasto)!=='D')}
                              placeholder='ej:11647580-4'
                              onIonInput={(e) => {setFactor2(e.target.value)}}
                              helperText='Ingrese factura de gastos válida'
                              errorText="Número factura incorrecta">
                            </IonInput>
                          </div>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol size="6">
                          <div className="ion-padding-start ion-padding-end">
                            <IonInput
                              value={factor3}
                              ref={factor3Ref}
                              type='text'
                              labelPlacement="floating"
                              clearInput={false}
                              label='Factor (3)'
                              disabled={gasto===undefined || (gasto!==undefined && getTypeOfSpending(gasto)!=='D')}
                              placeholder='ej:11647580-4'
                              onIonInput={(e) => {setFactor3(e.target.value)}}
                              helperText='Ingrese factura de gastos válida'
                              errorText="Número factura incorrecta">
                            </IonInput>
                          </div>
                        </IonCol>

                        <IonCol size="6">
                          <div className="ion-padding-start ion-padding-end">
                            <IonInput
                              value={factor4}
                              ref={factor4Ref}
                              type='text'
                              labelPlacement="floating"
                              clearInput={false}
                              label='Factor (4)'
                              disabled={gasto===undefined || (gasto!==undefined && getTypeOfSpending(gasto)!=='D')}
                              placeholder='ej:11647580-4'
                              onIonInput={(e) => {setFactor4(e.target.value)}}
                              helperText='Ingrese factura de gastos válida'
                              errorText="Número factura incorrecta">
                            </IonInput>
                          </div>
                        </IonCol>
                        <IonCol size="6">
                          <div className="ion-padding-start ion-padding-end">
                            <IonInput
                              value={factor5}
                              ref={factor5Ref}
                              type='text'
                              labelPlacement="floating"
                              clearInput={false}
                              label='Factor (5)'
                              disabled={gasto===undefined || (gasto!==undefined && getTypeOfSpending(gasto)!=='D')}
                              placeholder='ej:11647580-4'
                              onIonInput={(e) => {setFactor4(e.target.value)}}
                              helperText='Ingrese factura de gastos válida'
                              errorText="Número factura incorrecta">
                            </IonInput>
                          </div>
                        </IonCol>

                      </IonRow>

                      <IonCol size="12" className='ion-padding'>
                        <IonButton type="submit" color="primary" disabled={checkInput()} onClick={() => saveInformation(id===undefined)}>Grabar</IonButton>
                        <IonButton type="submit" color="primary">Limpiar</IonButton>
                      </IonCol>

                    </IonContent>
                  </IonModal>                              

                  <DataGrid 
                        rows={rows} 
                        columns={columns} 
                        // columnGroupingModel={columnGroupingModel} 
                        editMode="row"
                        rowModesModel={rowModesModel}
                        checkboxSelection
                        // rowSelectionModel={rowSelected}
                        // onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        onRowSelectionModelChange={(ids) => setRowSelected(ids)}
                        rowSelectionModel={rowSelected}                            
                        hideFooterSelectedRowCount
                        slotProps={{ pagination: { labelRowsPerPage: 'Registros por página'}}}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Datos;
