import React from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonItem, IonList, IonTitle, IonIcon, IonToolbar, IonSearchbar } from '@ionic/react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { IonInput, IonCheckbox, IonSelect, IonSelectOption, IonAlert, useIonAlert } from '@ionic/react';
import { IonRadio, IonRadioGroup, IonImg, IonButton, IonProgressBar, IonModal, useIonToast } from '@ionic/react';

import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DataGrid, GridRowsProp, GridColDef, GridColumnGroupingModel, GridRowModesModel, GridRowModes, GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';

import Checkbox from "@mui/material/Checkbox";
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import DenseMenu from './menu-main'
import { useParams } from 'react-router';
import { useCallback, useEffect, useRef, useState } from 'react'

import { gridOutline, closeOutline, sendOutline, navigateOutline, checkmarkDoneOutline, search, lockClosed, add, trash, create, pencil } from 'ionicons/icons';

import { 
  getStudentFile, 
  getStudentMovement, 
  getGlossFile,
  addStudentMovement, 
  updateStudentMovement, 
  deleteStudentMovement, 
  getSpendingGroup,
  getAdquisitionMovementByProof
} from '../service/dataservice';

import { OverlayEventDetail } from '@ionic/core/components';

import './factura.css';

const Factura: React.FC = () => {

  interface StudentFile {
    id:number;
    rut:string;
    apellidos:string;
  }

  interface StudentMovements {
    id:number;
    stfid:number;
    fecha: Date | undefined;
    comprobante: string;
    glosa: string;
    debe: number;
    haber: number; 
    saldo: number;
    spgid:number;
    spgtipo:string;
    spgorden:number;
    trvfactor1:number;
    trvfactor2:number;
    trvfactor3:number;
    trvfactor4:number;
    trvfactor5:number;
    totaldeudas:number;
    ingresoalimentacion:number; 
    deudaalimentacion:number;
    ingresolavado:number;
    deudalavado:number;
    ingresovestuario:number;
    deudavestuario:number;
    ingresotextoestudio:number;
    deudatextoestudio:number;
    ingresobienesmuebles:number;
    deudabienesmuebles:number;
  }

  interface GlossFile{
    id: number;
    descripcion: string;
  }

  interface SpendingGroup{
    id: number;
    descripcion: string;
    orden: number;
    tipo:string;
  }

  useEffect(() => {
    setEditMode(false)

    const loadData = async () => {
      setIsLoaded(true)

      getStudentFile().then(data => {
        setStudentFile(data.data)
        setResults(data.data)

        getGlossFile().then(data => {
          setGlossFile (data.data)
          setResultsGlossSearch(data.data)

          getSpendingGroup().then(data => {
            data.data.splice(0, 0, {descripcion:'Seleccione item', id:0, orden:0, tipo:''});
            setSpendingGroup(data.data)
            setGasto(data.data[0])
          })
          .catch(error => {
            setIsLoaded(false)
          })
        })
        .catch(error => {
          setIsLoaded(false)
        })

        setIsLoaded(false)

        // setStudentSums()
        // toggleMenu(false)
      
      })
      .catch(error => {
        setIsLoaded(false)
      })
    };

    loadData();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const modal = useRef<HTMLIonModalElement>(null);
  const modalGlosa= useRef<HTMLIonModalElement>(null);
  const modalComprobanteGlosa= useRef<HTMLIonModalElement>(null);

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setModalComprobanteGlosaVisible(false)

    if (ev.detail.role === 'confirm') {
      // setMessage(`Hello, ${ev.detail.data}!`);
    }
  }

  const rutRef = useRef<HTMLIonInputElement>(null);
  const inputDeber = useRef<HTMLIonInputElement>(null);
  const inputHaber = useRef<HTMLIonInputElement>(null);
  
  const buttonRef= useRef<HTMLIonButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    familaGasto: any
  ) => {

    const gasto=familaGasto
    setGasto(familaGasto); 

    setSelectedIndex(index);
    setAnchorEl(null);

    if(gasto?.tipo==='H'){inputHaber.current?.setFocus();}
    if(gasto?.tipo==='D'){inputDeber.current?.setFocus();}
  };

  const [isLoaded, setIsLoaded]= React.useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [studentFile, setStudentFile] = React.useState<StudentFile[]>([]);
  const [glossFile, setGlossFile]= React.useState<GlossFile[]>([]);

  const [studentMovement, setStudentMovement] = React.useState<StudentMovements[]>([]);
  const [spendingGroup, setSpendingGroup] = React.useState<SpendingGroup[]>([]);
  const [rowSelected, setRowSelected] = React.useState<any>([])

  const [modalComprobanteGlosaVisible, setModalComprobanteGlosaVisible]= React.useState<boolean>(false)
  const [resultsProofGlossSearch, setResultsProofGlossSearch]= React.useState<any[]>([]);

  const balanceAdjustment = (data:StudentMovements[])=>{
    
    let saldoAnterior=0
    data.forEach(movement=>{
      saldoAnterior=saldoAnterior + (movement.spgtipo==='D' ? movement.debe : movement.haber)
      movement.saldo= saldoAnterior * (-1)
      movement.totaldeudas=movement.saldo * (-1)

      movement.ingresoalimentacion=0
      movement.ingresolavado=0
      movement.ingresovestuario=0
      movement.ingresotextoestudio=0
      movement.ingresobienesmuebles=0

      movement.deudaalimentacion=0
      movement.deudalavado=0
      movement.deudavestuario=0
      movement.deudatextoestudio=0
      movement.deudabienesmuebles=0

      switch(movement.spgorden) {
        case 1:
          movement.ingresoalimentacion=movement.spgtipo==='D' ? movement.debe : 0
          movement.deudaalimentacion=movement.spgtipo==='H' ? movement.haber : 0
          break;
        case 2:
          movement.ingresolavado=movement.spgtipo==='D' ? movement.debe : 0
          movement.deudalavado=movement.spgtipo==='H' ? movement.haber : 0
          break;

        case 3:
          movement.ingresovestuario=movement.spgtipo==='D' ? movement.debe : 0
          movement.deudavestuario=movement.spgtipo==='H' ? movement.haber : 0
          break;
        case 4:
          movement.ingresotextoestudio=movement.spgtipo==='D' ? movement.debe : 0
          movement.deudatextoestudio=movement.spgtipo==='H' ? movement.haber : 0
          break;
        case 5:
          movement.ingresobienesmuebles=movement.spgtipo==='D' ? movement.debe : 0
          movement.deudabienesmuebles=movement.spgtipo==='H' ? movement.haber : 0
          break;
        case 6:
          if(movement.spgtipo==='D') {
            movement.ingresoalimentacion=movement.debe*movement.trvfactor1
            movement.ingresolavado=movement.debe*movement.trvfactor2
            movement.ingresovestuario=movement.debe*movement.trvfactor3
            movement.ingresotextoestudio=movement.debe*movement.trvfactor4
            movement.ingresobienesmuebles=movement.debe*movement.trvfactor5
          }
          break;
      }

    })
    return data
  }

  const toggleMenu=(visible:boolean)=>{
    document.querySelector('ion-menu')!.style.display=visible? "block" : "none"
  }

  const onRowsSelectionHandler = (ids:any) => {
    const selectedRowsData = ids.map((id:any) => rows.find((row) => row.id === id));

    setRowSelected(selectedRowsData)
  };

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [isSearchVisible, setIsSearchVisible]= useState<boolean>();

  const rows: GridRowsProp = studentMovement
  const columns: GridColDef[] = [
    {field:'fecha', headerName:'Fecha', type: 'string', width: 100, editable: true},
    {field:'comprobante', headerName:'Nº comp.', width: 100, editable: true},
    {field:'glosa', headerName:'Glosa', width: 150, editable: true},
    {field:'debe', headerName:'Debe', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'haber', headerName:'Haber', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'saldo', headerName:'Saldo', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'totaldeudas', headerName:'Total deudas', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'ingresoalimentacion', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'deudaalimentacion', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'ingresolavado', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'deudalavado', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'ingresovestuario', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'deudavestuario', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'ingresotextoestudio', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'deudatextoestudio', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'ingresobienesmuebles', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
    {field:'deudabienesmuebles', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => value==0 ? '' : currencyFormatter.format(Number(value))},
  ]

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'alimentacion',
      description: 'Information about the character',
      headerName: 'Alimentación',
      headerAlign: 'center',
      children: [{ field: 'ingresoalimentacion' }, { field: 'deudaalimentacion' }]
    },
    {
      groupId: 'lavado',
      description: 'Information about the character',
      headerName: 'Lavado',
      headerAlign: 'center',
      children: [{ field: 'ingresolavado' }, { field: 'deudalavado' }]
    },
    {
      groupId: ' vestuario',
      description: 'Information about the character',
      headerName: 'Vestuario y equipo',
      headerAlign: 'center',
      children: [{ field: 'ingresovestuario' }, { field: 'deudavestuario' }]
    },
    {
      groupId: ' textos',
      description: 'Information about the character',
      headerName: 'Textos de estudio',
      headerAlign: 'center',
      children: [{ field: 'ingresotextoestudio' }, { field: 'deudatextoestudio' }]
    },
    {
      groupId: ' bienes',
      description: 'Information about the character',
      headerName: 'Bienes y muebles',
      headerAlign: 'center',
      children: [{ field: 'ingresobienesmuebles' }, { field: 'deudabienesmuebles' }]
    },

  ];

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  const [results, setResults] = useState([...studentFile]);
  const [resultsGlossSearch, setResultsGlossSearch] = useState([...glossFile]);

  const [showCheckRut, setShowCheckRut]= useState(false)
  const [rut, setRut] = useState<any>()
  const [studentId, setStudentId] = useState<any>()
  const [apellidos, setApellidos] = useState<any>()
  const [apellidoSearch, setApellidoSearch] = useState('')
  const [glosaSearch, setGlosaSearch] = useState('')
  const [comprobanteGlosaSearch, setComprobanteGlosaSearch] = useState('')

  const [fecha, setFecha]=useState<any>()
  const [comprobante, setComprobante]=useState<any>()
  const [glosa, setGlosa]=useState<any>()
  const [gasto, setGasto]=React.useState<SpendingGroup>()
  const [debe, setDebe]=useState<any>()
  const [haber, setHaber]=useState<any>()

  const [editMode, setEditMode]=useState<boolean>()
  
  const handleInput = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    if(query.length==0){
      setResults(studentFile.filter((d) => d.apellidos.toLowerCase().indexOf('') > -1));
    }else{
      setResults(studentFile.filter((d) => d.apellidos.toLowerCase().indexOf(query) > -1));
    }
    setApellidoSearch(target.value!);
  };

  const handleSearchGlossInput = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    if(query.length==0){
      setResultsGlossSearch(glossFile.filter((d) => d.descripcion.toLowerCase().indexOf('') > -1));
    }else{
      setResultsGlossSearch(glossFile.filter((d) => d.descripcion.toLowerCase().indexOf(query) > -1));
    }
    setGlosaSearch(target.value!);
  };

  const name='Administración cartola'
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();

  const handleItemSearchClick= (e:any)=>{
    getStudentMovement(e.id).then(data => {
      setStudentMovement(balanceAdjustment(data.data))
    })

    setShowCheckRut(true)
    setRut(e.rut);
    setStudentId([e.id])
    setApellidos(e.apellidos)

    modal.current?.dismiss()
  }

  const returnSpendingFromProof:any=()=>{
    const spendingFromProof={
      id: resultsProofGlossSearch[0].spgid,
      descripcion:resultsProofGlossSearch[0].glodescripcion,
      orden:0,
      tipo:resultsProofGlossSearch[0].spgtipo
    }

    return [spendingFromProof]
  }

  const handleItemSearchGlossClick= (e:any)=>{
    const spendingFromProof:any=returnSpendingFromProof()

    setGlosa(e.descripcion)

    setGlosa(e.glodescripcion)
    setDebe(e.spgtipo=='D' ? resultsProofGlossSearch[0].acmremuneracion : null)
    setHaber(e.spgtipo=='H' ?  e.acmvalorindividual : null)
    setSpendingGroup(spendingFromProof)
    setGasto(spendingFromProof[0])

    modalGlosa.current?.dismiss()
  }

  const [messageToast] = useIonToast();

  const showMesssage=(position: 'top' | 'middle' | 'bottom', msg:any)=>{
    messageToast({
      message: msg,
      duration: 1500,
      position: position,
    });
  }

  const clearStudentData=(e:any)=>{
    // showMesssage('top', 'Rut no se encuentra en la nómina, intente nuevamente')
    rutNotFound({
      header:"Administración cartola",
      subHeader:"Ingreso de movimientos",
      message:"Rut ingresado no se encuentra en la nómina, intente nuevamente",
      buttons:[
        {
          text: 'Entendido',
          role: 'cancel',
          handler: () => {
            setShowCheckRut(false)
            setRut(undefined)
            setApellidos(null)
            setStudentMovement([])
          },
        }
      ]
    })
  }

  const displayStudentData=(e:any)=>{
    setIsLoaded(true)

    const result=studentFile.filter((d) => d.rut.toLowerCase().indexOf(e) > -1)
    setApellidos(result[0].apellidos)

    getStudentMovement(result[0].id).then(data => {
      setStudentMovement(balanceAdjustment(data.data))
    })
    .catch(error => {
      setIsLoaded(false)
    })


    setIsLoaded(false)
  }

  const checkRut=(e:any)=>{
    const result=studentFile.filter((d) => d.rut.toLowerCase().indexOf(e) > -1)

    return result.length>0
  }

  const checkInputRut=(value:any)=>{
    if(value==='' || value===null){
      rutRef.current?.setFocus()

      setShowCheckRut(false)
      setRut(undefined)
      setApellidos(null)

      setStudentMovement([])
    }else{
      setShowCheckRut(true)
      setRut(value)
    }
  }

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    inputHaber.current?.setFocus()
    inputDeber.current?.setFocus()

    setAnchorEl(event.currentTarget);

    // buttonRef.current?.click();
  };

  const handleModifyClick = (rowSelected:any)=>{
    const dataRow=rowSelected.map((id:any) => rows.find((row) => row.id === id));

    const fecha=dataRow[0].fecha.substr(6, 4) + '-' + dataRow[0].fecha.substr(3, 2) + '-' + dataRow[0].fecha.substr(0, 2)

    setFecha(fecha)
    setComprobante(dataRow[0].comprobante)
    setGlosa(dataRow[0].glosa)
    setDebe(dataRow[0].debe)
    setHaber(dataRow[0].haber)

    setGasto(spendingGroup[dataRow[0].spgid])
    setEditMode(true)

    validateProof(dataRow[0].comprobante, false)
  }

  const handleSearchClick = ()=>{
    setResults(studentFile.filter((d) => d.apellidos.toLowerCase().indexOf('') > -1));
  }

  const handleSearchGlossClick = ()=>{
    const spendingFromProof:any=returnSpendingFromProof()
    
    setSpendingGroup(spendingFromProof)
    setGasto(spendingFromProof[0])
  }

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setIsValid(undefined);

    if (value === '') return;
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  const clearValuesOfForm=()=>{
    setFecha(null)
    setComprobante(null)
    setGlosa(null)
    setGasto(spendingGroup[0])
    setDebe(null)
    setHaber(null)
  }

  const addMovements=(payload:any)=>{
    addStudentMovement(payload).then(data => {
      if(rut!==undefined){
        getStudentMovement(payload.stfid).then(data => {
          setStudentMovement(balanceAdjustment(data.data))
        })
        .catch(error => {
          setIsLoaded(false)
          console.log(error)
        })
      }

      setFecha(null)
      setComprobante(null)
      setGlosa(null)
      setGasto(spendingGroup[0])
      setDebe(null)
      setHaber(null)

      setEditMode(false)
      setIsLoaded(false)
    })
    .catch(error => {
      setIsLoaded(false)
      console.log(error)
    })

  }

  const validateProof=(proof:any, newRecord:boolean)=>{
    getAdquisitionMovementByProof(proof).then(data => {
      setResultsProofGlossSearch(data.data)

      const spendingFromProof:any=returnSpendingFromProof()

      if(resultsProofGlossSearch.length>1){
        setModalComprobanteGlosaVisible(true)
      }else{
        setFecha(resultsProofGlossSearch[0].acmfecha)
        setGlosa(resultsProofGlossSearch[0].glodescripcion)

        if(!newRecord){
          const dataRow=rowSelected.map((id:any) => rows.find((row) => row.id === id));

          setDebe(dataRow[0].debe)
          setHaber(dataRow[0].haber)
        }else{
          setDebe(resultsProofGlossSearch[0].spgtipo=='D' ? resultsProofGlossSearch[0].acmremuneracion : null)
          setHaber(resultsProofGlossSearch[0].spgtipo=='H' ?  resultsProofGlossSearch[0].acmvalorindividual : null)
        }

        setSpendingGroup(spendingFromProof)
        setGasto(spendingFromProof[0])
      }
    })
    .catch(error => {
      setIsLoaded(false)
      console.log(error)
    })
    
  }

  const saveMovement=(isOK:any)=>{
    if (isOK){
      const body={
        stmid:undefined,
        stfid:studentFile.map((record:any) => record.id),
        stmfecha:fecha, 
        stmcomprobante:comprobante, 
        stmglosa:glosa, 
        stmdebe:debe===undefined ? 0 : debe, 
        stmhaber:haber===undefined ? 0 : haber, 
        spgid:gasto?.id, 
        stmingresoalimentacion:0, 
        stmdeudaalimentacion:0, 
        stmingresolavado:0, 
        stmdeudalavado:0, 
        stmingresovestuario:0, 
        stmdeudavestuario:0, 
        stmingresotextoestudio:0, 
        stmdeudatextoestudio:0, 
        stmingresobienesmuebles:0, 
        stmdeudabienesmuebles:0
      }

      setIsLoaded(true)

      if(!editMode){
        if(rut===undefined){
          massiveMovements({
            header:"Administración cartola",
            subHeader:"Ingreso de movimientos masivos",
            message:"Atención, a continuación se generará un movimiento masivo para todo el personal, ¿Desea continuar con este proceso?",
            buttons:[
              {
                text: 'No',
                role: 'cancel',
                handler: () => {
                  console.log('Alert canceled');
                },
              },
              {
                text: 'Continuar',
                role: 'confirm',
                handler: () => {
                  addMovements(body)
                  console.log('Alert confirmed');
                }
              }
            ]
          })
        }else{
          body.stfid=studentId

          addMovements(body)
        }
        
        
      }else{
        body.stmid=rowSelected[0]
        body.stfid=studentId

        updateStudentMovement(body).then(data => {
          getStudentMovement(body.stfid).then(data => {
            setStudentMovement(balanceAdjustment(data.data))

            setFecha(null)
            setComprobante(null)
            setGlosa(null)
            setGasto(spendingGroup[0])
            setDebe(null)
            setHaber(null)

            setEditMode(false)
            setIsLoaded(false)
          })
          .catch(error => {
            setIsLoaded(false)
            console.log(error)
          })
        })
        .catch(error => {
          setIsLoaded(false)
          console.log(error)
        })
      }
      
      setRowSelected([])
    }

  }

  const checkline=()=>{
    let result=false

    if(rut!=undefined){
      if(!checkRut(rut)){
        return false
      }
    }

    if(fecha != undefined){
      if(comprobante != undefined){
        if(glosa != undefined){
          if(resultsProofGlossSearch != undefined){
            if(resultsProofGlossSearch.length==0){
              if(gasto?.tipo==='D'){
                if(debe != undefined && debe>0){
                  result=true
                }else{
                  result=false
                }
              }else{
                if(haber != undefined && haber>0){
                  result=true
                }else{
                  result=false
                }
              }
            }else{
              if(resultsProofGlossSearch[0].spgtipo==='D'){
                if(debe != undefined && debe>0){
                  result=true
                }else{
                  result=false
                }
              }else{
                if(haber != undefined && haber>0){
                  result=true
                }else{
                  result=false
                }
              }
            }
          }else{
            result=false
          }
        }else{
          result=false
        }
      }else{
        result=false
      }
    }else{
      result=false
    }

    return result
  }

  const [massiveMovements] = useIonAlert();
  const [rutNotFound] = useIonAlert();
  // buttonRef.current?.addEventListener('click', saveMovement);

  // setStudentSums()
  // toggleMenu(false)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <DenseMenu />
            {/* <IonButton onClick={()=>{toggleMenu(true)}}>
              <IonIcon slot="icon-only" icon={gridOutline}></IonIcon>
            </IonButton> */}
          </IonButtons>

          <IonTitle>{name}</IonTitle>
          {isLoaded &&
            <IonProgressBar type="indeterminate"></IonProgressBar>
          }
        </IonToolbar>

        {/* {isSearchVisible &&
          <IonToolbar>
            {isSearchVisible &&
              <IonSearchbar placeholder="Búsqueda de alumnos..." debounce={1000} onIonInput={(ev) => handleInput(ev)}></IonSearchbar>
            }

            {isSearchVisible &&
              <IonList>
                {results.map((result, index) => (
                  <IonItem onClick={() =>handleItemSearchClick(result)} button={true} key={index}>{result.apellidos}</IonItem>
                ))}
              </IonList>
            }          
          </IonToolbar>
        } */}
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="container-evento">
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-padding">
                  <IonRow>
                    <IonCol size="2">
                      <IonInput
                          value={rut}
                          ref={rutRef}

                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='text'
                          labelPlacement="floating"
                          clearInput={false}
                          label='RUT'
                          placeholder='ej:11647580-4'
                          onIonInput={(e) => {checkInputRut(e.target.value)}}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          {/* <IonIcon slot="end" icon={navigateOutline} aria-hidden="true" onClick={handleSearchClick}></IonIcon> */}

                          
                          <IonButtons slot="end">
                            <IonButton onClick={()=>{checkInputRut('')}}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                            {
                              showCheckRut ? (
                                <IonButton onClick={()=>{!checkRut(rut) ? clearStudentData(rut) : displayStudentData(rut)}}>
                                  <IonIcon slot="icon-only" icon={checkmarkDoneOutline}></IonIcon>
                                </IonButton>
                              ) : ''
                            }
                          </IonButtons>

                          {/* <IonButton fill="clear" slot="end" aria-label="Show/hide">
                            <IonIcon slot="icon-only" name={navigateOutline} aria-hidden="true"></IonIcon>
                          </IonButton>  */}

                        </IonInput>
                    </IonCol>
                    <IonCol size="4">
                        <IonInput
                            value={apellidos}
                            className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                            type='text'
                            labelPlacement="floating" 
                            label='Nombres y apellidos'
                            readonly={true}
                            placeholder='ej:5643245'
                            onIonInput={(event) => validate(event)}
                            onIonBlur={() => markTouched()}
                            helperText='Ingrese factura de gastos válida'
                            errorText="Número factura incorrecta">
                            <IonButtons slot="end">
                              <IonButton id="open-modal" onClick={()=>{handleSearchClick}}>
                                <IonIcon slot="icon-only" icon={search}></IonIcon>
                              </IonButton>
                            </IonButtons>
                        </IonInput>

                        <IonModal ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
                          <IonHeader>
                            <IonToolbar>
                              {/* <IonButtons slot="start">
                                <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
                              </IonButtons> */}
                              <IonTitle>Búsqueda de alumnos por apellidos</IonTitle>
                              <IonButtons slot="end">
                                <IonButton onClick={() => modal.current?.dismiss()}>
                                  <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                                </IonButton>
                              </IonButtons>
                            </IonToolbar>
                          </IonHeader>
                          <IonContent className="ion-padding">
                            <IonSearchbar 
                              value={apellidoSearch} 
                              placeholder="ej:Gonzalez Labbe" 
                              debounce={1000} 
                              onIonInput={(e) => { handleInput(e)}}>
                            </IonSearchbar>
                            <IonList>
                              {results.map((result, index) => (
                                <IonItem onClick={() =>handleItemSearchClick(result)} button={true} key={index}>{result.apellidos}</IonItem>
                              ))}
                            </IonList>

                            {/* <IonItem>
                              <IonInput
                                label="Enter your name"
                                labelPlacement="stacked"
                                ref={input}
                                type="text"
                                placeholder="Your name"
                              />
                            </IonItem> */}
                          </IonContent>
                        </IonModal>

                    </IonCol>
                  </IonRow>                  
                </IonCol>
                <IonCol size="12" className="ion-padding">
                  <IonRow>
                    <IonCol size="1.5">
                      <IonInput
                        value={fecha}
                        className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                        type='date'
                        labelPlacement="floating" 
                        label='Fecha'
                        placeholder='ej:20-03-2024'
                        onIonInput={(e) => {setFecha(e.target.value)}}//validate(event)}
                        onIonBlur={() => markTouched()}
                        helperText='Ingrese factura de gastos válida'
                        errorText="Número factura incorrecta">
                        <IonButtons slot="end">
                          <IonButton onClick={() => setFecha(null)}>
                            <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                          </IonButton>
                        </IonButtons>

                        </IonInput>
                    </IonCol>


                    <IonCol size="1.5">
                      <IonInput
                        value={comprobante}

                        className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                        type='text'
                        labelPlacement="floating" 
                        label='Nº comprobante'
                        placeholder='ej:5643245'
                        onIonInput={(e) => setComprobante(e.target.value)}//validate(event)}
                        onIonBlur={(e) => validateProof(comprobante, true)}
                        helperText='Ingrese factura de gastos válida'
                        errorText="Número factura incorrecta">
                        <IonButtons slot="end">
                          <IonButton onClick={() => setComprobante(null)}>
                            <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                          </IonButton>
                        </IonButtons>

                      </IonInput>
                    </IonCol>

                    <IonCol size="3.0">
                      <IonInput 
                          value={glosa}
                          
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='text'
                          readonly={true}
                          labelPlacement="floating" 
                          label='Glosa'
                          placeholder='ej:5643245'
                          onIonInput={(e) => setGlosa(e.target.value)}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonButtons slot="end">
                            <IonButton id="open-modal-glosa" onClick={()=>{handleSearchGlossClick}}>
                              <IonIcon slot="icon-only" icon={search}></IonIcon>
                            </IonButton>
                          </IonButtons>
                      </IonInput>

                      <IonModal ref={modalGlosa} isOpen={modalComprobanteGlosaVisible} trigger="open-modal-glosa" onWillDismiss={(ev) => onWillDismiss(ev)}>
                        <IonHeader>
                          <IonToolbar>
                            <IonTitle>Búsqueda de glosas</IonTitle>
                            <IonButtons slot="end">
                              <IonButton onClick={() => {modalGlosa.current?.dismiss(); setModalComprobanteGlosaVisible(false)}}>
                                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                              </IonButton>
                            </IonButtons>
                          </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                          <IonSearchbar 
                            value={glosaSearch} 
                            placeholder="ej:LAVANDERIA" 
                            debounce={1000} 
                            onIonInput={(e) => { handleSearchGlossInput(e)}}>
                          </IonSearchbar>
                          <IonList>
                            {/* {resultsGlossSearch.map((result, index) => (
                              <IonItem onClick={() =>handleItemSearchGlossClick(result)} button={true} key={index}>{result.descripcion}</IonItem>
                            ))} */}
                            {resultsProofGlossSearch.map((result, index) => (
                              <IonItem onClick={() =>handleItemSearchGlossClick(result)} button={true} key={index}>{result.glodescripcion}</IonItem>
                            ))}
                          </IonList>
                        </IonContent>
                      </IonModal>

                    </IonCol>

                    {/* <IonCol size="1.5">
                        <IonList>
                            <IonItem>
                              <IonSelect 
                                aria-label="Spending" interface="popover" placeholder="Seleccione item"
                                onClick={handleClickListItem}
                                onIonChange={(e) => { setGasto(e.detail.value)}}
                              >
                                {spendingGroup.map((spending) => (
                                  <IonSelectOption key={spending.id} value={spending}>
                                    {spending.descripcion}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>

                              <IonButton color="light" fill="outline" expand="full" type="submit" onClick={handleClickListItem}>{gasto?.descripcion}</IonButton>
                              <IonInput 
                                labelPlacement="floating" 
                                label="Familia gasto" 
                                helperText='Ingrese factura de gastos válida'
                                placeholder="ej:ALIMENTACION"
                                readonly={true}
                                value={gasto?.descripcion}>

                                <IonButtons slot="end" className="search-gasto">
                                  <IonIcon slot="end" icon={search} aria-hidden="true" onClick={handleClickListItem}></IonIcon>
                                </IonButtons>
                              </IonInput>

                              <Menu
                                id="lock-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                  'aria-labelledby': 'lock-button',
                                  role: 'listbox',
                                }}
                              >
                                {spendingGroup.map((spending, index) => (
                                  <MenuItem
                                    key={index}
                                    disabled={index === 0}
                                    selected={index === selectedIndex}
                                    onClick={(e) => {handleMenuItemClick(e, index, spending)}}
                                  >
                                    {spending.descripcion}
                                  </MenuItem>
                                ))}
                            </Menu>           

                            </IonItem>
                        </IonList>
                    </IonCol> */}

                    <IonCol size="1.5">
                      <IonInput
                          value={debe}
                          ref={inputDeber}

                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='number'
                          labelPlacement="floating" 
                          label='Debe'
                          placeholder='ej:5643245'
                          disabled={gasto?.tipo!='D'}
                          onIonInput={(e) => {setHaber(null); setDebe(e.target.value)}}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">

                          <IonButtons slot="end">
                            <IonButton onClick={() => setDebe(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                        </IonInput>
                    </IonCol>

                    <IonCol size="1.5">
                      <IonInput
                          value={haber}
                          ref={inputHaber} 
                          
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='number'
                          labelPlacement="floating" 
                          label='Haber'
                          placeholder='ej:5643245'
                          disabled={gasto?.tipo!='H'}
                          onIonInput={(e) => {setDebe(null); setHaber(e.target.value)}}//validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">

                          <IonButtons slot="end">
                            <IonButton onClick={() => setHaber(null)}>
                              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                            </IonButton>
                          </IonButtons>

                        </IonInput>
                    </IonCol>

                    <IonCol size="2" className='ion-text-right'>
                      <IonButton id="save-movement" type="submit" disabled={!checkline()} color="primary" onClick={() =>{ saveMovement(checkline())}}>Grabar</IonButton>
                      <IonButton type="submit" color="primary" onClick={() =>{ clearValuesOfForm()}}>Limpiar</IonButton>
                    </IonCol>
                  </IonRow>
                </IonCol>

                <IonCol size="12">
                  <IonRow>
                    <IonCol size="12">
                      <Toolbar
                        sx={[
                          {
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                          },
                          rowSelected.length > 0 && {
                            bgcolor: (theme) =>
                              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                          },
                        ]}
                      >
                        {rowSelected.length > 0 ? (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                          >
                            {rowSelected.length} registro(s) seleccionado(s)
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                          >
                            Detalle de movimientos
                          </Typography>
                        )}
                        {rowSelected.length > 0 ? (
                          <IonButtons slot="end">
                            <IonButton id="delete-rows" >
                              <IonIcon icon={trash} color="primary" size="large"></IonIcon>
                                <IonAlert
                                  trigger="delete-rows"
                                  header="Administración cartola"
                                  subHeader="Eliminación de movimientos"
                                  message="A continuación se eliminarán los registros seleccionados, ¿Desea continuar con este proceso?"
                                  buttons={[
                                    {
                                      text: 'Volver',
                                      role: 'cancel',
                                      handler: () => {
                                        console.log('Alert canceled');
                                      },
                                    },
                                    {
                                      text: 'Eliminar',
                                      role: 'confirm',
                                      handler: () => {
                                        console.log('Alert confirmed');

                                        setIsLoaded(true)
                                        deleteStudentMovement(rowSelected).then(data => {
                                          getStudentMovement(studentId).then(data => {
                                            setStudentMovement(balanceAdjustment(data.data))
                                          setIsLoaded(false)
                                          })
                                          .catch(error => {
                                            setIsLoaded(false)
                                            console.log(error)
                                          })
                                        })
                                        .catch(error => {
                                          setIsLoaded(false)
                                          console.log(error)
                                        })
                                      }
                                    }
                                  ]}
                                >
                                </IonAlert>
                            </IonButton>

                            {rowSelected.length == 1 ? (
                            <IonButton id="modify-rows" onClick={() =>handleModifyClick(rowSelected)}>
                              <IonIcon icon={create} color="primary" size="large"></IonIcon>
                            </IonButton>
                            ) : '' }
                          </IonButtons>
                        ) : '' }
                      </Toolbar>

                      <DataGrid 
                        rows={rows} 
                        columns={columns} 
                        columnGroupingModel={columnGroupingModel} 
                        editMode="row"
                        rowModesModel={rowModesModel}
                        checkboxSelection
                        // rowSelectionModel={rowSelected}
                        // onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        onRowSelectionModelChange={(ids) => setRowSelected(ids)}
                        rowSelectionModel={rowSelected}                            
                        hideFooterSelectedRowCount
                        slotProps={{ pagination: { labelRowsPerPage: 'Registros por página'}}}
                        />
                    </IonCol>                    
                  </IonRow>

                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>

  )
}

export default Factura;
