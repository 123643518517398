import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
// import { gridOutline, closeOutline, sendOutline, navigateOutline, checkmarkDoneOutline, search, lockClosed, add, trash, create, pencil } from 'ionicons/icons';

import { cashOutline, analyticsOutline, analyticsSharp, pricetagOutline, pricetagSharp, gitNetworkOutline, gitNetworkSharp, 
  fileTrayFullOutline, fileTraySharp,
  gridOutline, chevronBackOutline, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import { link } from 'fs';
import Divider from '@mui/material/Divider';

export default function Densemenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
    divider?:boolean;
  }
  
  const appPages: AppPage[] = [
    {
      title: 'Usuarios',
      url: '/factura',
      iosIcon: fileTrayFullOutline,
      mdIcon: fileTraySharp
    },

    {
      title: 'Alumnos',
      url: '/factura',
      iosIcon: fileTrayFullOutline,
      mdIcon: fileTraySharp
    },

    {
      title: 'Administración cartola',
      url: '/factura',
      iosIcon: fileTrayFullOutline,
      mdIcon: fileTraySharp
    },
    {
      title: 'Datos',
      url: '/datos',
      iosIcon: analyticsOutline,
      mdIcon: analyticsSharp
    },
    {
      title: 'Adquisiciones',
      url: '/adquisiciones',
      iosIcon: pricetagOutline,
      mdIcon: pricetagSharp
    },
    {
      title: 'Billetaje',
      url: '/billetaje',
      iosIcon: cashOutline,
      mdIcon: cashOutline
    },
  
    {
      title: 'Relación',
      url: '/relacion',
      iosIcon: gitNetworkOutline,
      mdIcon: gitNetworkSharp
    }
  ];

  const index=1;

  const toggleMenu=(visible:boolean)=>{
    document.querySelector('ion-menu')!.style.display=visible? "block" : "none"
  }

  toggleMenu(false)

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <IonIcon slot="icon-only" icon={gridOutline} key={index}></IonIcon>
        {/* Dashboard */}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{  
          style: {  
            width: 350,  
          },  
       }} 
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}

        {appPages.map((appPage, index) => {
            return (
              <>
                {/* <MenuItem onClick={handleClose}>Profile2</MenuItem> */}
                <MenuItem 
                  onClick={handleClose} 
                  style={{maxWidth: 500}} 
                  key={index} 
                  component={Link} 
                  to={appPage.url}
                >
                  {appPage.title}
                </MenuItem>
              </>
                // <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                //   <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                //   <IonLabel>{appPage.title}</IonLabel>
                // </IonItem>
            );
          })}

      </Menu>
    </div>
  );
}