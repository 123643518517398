import React from 'react';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  useIonAlert, IonAlert} from '@ionic/react';
import { useParams } from 'react-router';
import { DataGrid, GridRowsProp, GridColDef, GridColumnGroupingModel, GridRowModesModel, GridRowModes, GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { IonCol, IonGrid, IonRow, IonButton, IonIcon } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react'
import Toolbar from '@mui/material/Toolbar';
import { closeOutline, sendOutline, navigateOutline, checkmarkDoneOutline, search, lockClosed, add, trash, create, pencil } from 'ionicons/icons';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { 
  getBilletaje,
} from '../service/dataservice';

import DenseMenu from './menu-main'
import './Page.css';

const Billetaje: React.FC = () => {
  interface Billetaje{
    id: number;
    enddescription: string;
    stadescription:string;
    stfrut:string;
    stfalumno:string;
    stfsexo:string;
    total:number;
    enero:number;
    febrero:number;
    marzo:number;
    abril:number;
    mayo:number;
  }

  // const { name, titlecontent } = useParams<{ name: string, titlecontent:string;}>();
  const name='Billetaje'

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  const [rowSelected, setRowSelected] = React.useState<any>([])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [isLoaded, setIsLoaded]= React.useState<boolean>(false)
  const [billetaje, setBilletaje]= React.useState<Billetaje[]>([]);
  const rows: GridRowsProp = billetaje

  const columns: GridColDef[] = [
    {field:'id', headerName:'ID', width: 100, editable: false},
    {field:'enddescription', headerName:'Escuadron', width: 200, editable: false},
    {field:'stadescription', headerName:'Estado', width: 150, editable: false},
    {field:'stfrut', headerName:'RUT', width: 100, editable: false},
    {field:'stfalumno', headerName:'Alumno', width: 300, editable: false},
    {field:'stfsexo', headerName:'Sexo', headerAlign: 'center', align:'center', width: 100, editable: false},
    {field:'total', headerName:'Total', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'enero', headerName:'Enero', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'febrero', headerName:'Febrero', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'marzo', headerName:'Marzo', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'abril', headerName:'Abril', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'mayo', headerName:'Mayo', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'junio', headerName:'Junio', headerAlign: 'right', align:'right',  width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'julio', headerName:'Julio', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'agosto', headerName:'Agosto', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'septiembre', headerName:'Septiembre', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'octubre', headerName:'Octubre', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'noviembre', headerName:'Noviembre', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))},
    {field:'diciembre', headerName:'Diciembre', headerAlign: 'right', align:'right', width: 100, editable: false, valueFormatter: (value) => value==0 || value==null ? '' : currencyFormatter.format(Number(value))}

  ]

  const handleModifyClick = (rowSelected:any)=>{
    const dataRow=rowSelected.map((id:any) => rows.find((row) => row.id === id));

  }

  useEffect(() => {
    const loadData = async () => {
      setIsLoaded(true)

      getBilletaje(2021).then(data => {
        const saldos:Billetaje[]=data.data
        saldos.forEach(movement =>{
          movement.total=movement.enero + movement.febrero + movement.marzo + movement.abril + movement.mayo
        })
        setBilletaje (data.data)
      })
      .catch(error => {
        console.log("error:", error)
        setIsLoaded(false)
      })
    }
    loadData();
  }, []);

  const [dataAlert] = useIonAlert();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <DenseMenu />
            {/* <IonMenuButton /> */}
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="container-evento">
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-padding">
                    <Toolbar
                        sx={[
                          {
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                          },
                          rowSelected.length > 0 && {
                            bgcolor: (theme) =>
                              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                          },
                        ]}
                      >
                        {rowSelected.length > 0 ? (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                          >
                            {rowSelected.length} registro(s) seleccionado(s)
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                          >
                            Detalle de movimientos
                          </Typography>
                        )}
                        {rowSelected.length > 0 ? (
                          <IonButtons slot="end">
                            <IonButton id="delete-rows" >
                              <IonIcon icon={trash} color="primary" size="large"></IonIcon>
                                <IonAlert
                                  trigger="delete-rows"
                                  header="Administración cartola"
                                  subHeader="Eliminación de movimientos"
                                  message="A continuación se eliminarán los registros seleccionados, ¿Desea continuar con este proceso?"
                                  buttons={[
                                    {
                                      text: 'Volver',
                                      role: 'cancel',
                                      handler: () => {
                                        console.log('Alert canceled');
                                      },
                                    },
                                    {
                                      text: 'Eliminar',
                                      role: 'confirm',
                                      handler: () => {
                                        console.log('Alert confirmed');

                                        setIsLoaded(true)
                                        // deleteStudentMovement(rowSelected).then(data => {
                                        //   getStudentMovement(studentId).then(data => {
                                        //     setStudentMovement(data.data)
                                        //   setIsLoaded(false)
                                        //   })
                                        //   .catch(error => {
                                        //     setIsLoaded(false)
                                        //     console.log(error)
                                        //   })
                                        // })
                                        // .catch(error => {
                                        //   setIsLoaded(false)
                                        //   console.log(error)
                                        // })
                                      }
                                    }
                                  ]}
                                >
                                </IonAlert>
                            </IonButton>

                            {rowSelected.length == 1 ? (
                            <IonButton id="modify-rows" onClick={() =>handleModifyClick(rowSelected)}>
                              <IonIcon icon={create} color="primary" size="large"></IonIcon>
                            </IonButton>
                            ) : '' }
                          </IonButtons>
                        ) : '' }
                    </Toolbar>

                  <DataGrid 
                        rows={rows} 
                        columns={columns} 
                        // columnGroupingModel={columnGroupingModel} 
                        // editMode="row"
                        rowModesModel={rowModesModel}
                        // checkboxSelection
                        // rowSelectionModel={rowSelected}
                        // onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        // onRowSelectionModelChange={(ids) => setRowSelected(ids)}
                        rowSelectionModel={rowSelected}                            
                        hideFooterSelectedRowCount
                        slotProps={{ pagination: { labelRowsPerPage: 'Registros por página'}}}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Billetaje;
